<template>
  <div class="flex items-center justify-center overflow-hidden">
    <canvas ref="canvas"
            class=""
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useDetectSize } from '../../composables/detect-size';
import type { WatchStopHandle } from 'nuxt/dist/app/compat/capi';

const size = useDetectSize();
const canvas = ref(null);

interface ThreeVideoProps {
  trigger: number;
}

const props = defineProps<ThreeVideoProps>();

// Config
let sequencePath = '';
const sequenceNumberLength = 3;
let fileName = '';
const fileSuffix = '.png';

let targetElement: HTMLCanvasElement;

const startFrame = 1;
const endFrame = 240;

// Functions

function padWithZeroes(number: number, length: number) {
  let paddedNumber = '' + number;
  while (paddedNumber.length < length) {
    paddedNumber = '0' + paddedNumber;
  }
  return paddedNumber;
}

const frames: HTMLImageElement[] = [];
let framesLoaded = 0;

function loadFrames() {
  for (let i = startFrame; i <= endFrame; i++) {
    frames[i] = new Image();
    frames[i].src = sequencePath + fileName + padWithZeroes(i, sequenceNumberLength) + fileSuffix;
    frames[i].onload = function () {
      framesLoaded++;
      if (framesLoaded > endFrame - startFrame) {
        onFramesLoaded();
      }
    };
  }
}

let unwatchTrigger: WatchStopHandle;

function onFramesLoaded() {
  unwatchTrigger = watch(() => props.trigger, trigger => {
    const frameIndex = Math.min(endFrame - 1, Math.ceil(trigger * endFrame));
    requestAnimationFrame(() => renderFrame(frameIndex + 1));
  }
  );
  
  renderFrame(1);
}

function renderFrame(currentFrame: number) {
  const canvas = targetElement;
  const context = canvas.getContext('2d');

  // ratio and canvas width is calculated based on image dimensions
  const ratio = size.value === 'mobile' ? 870 / 357 : 1181 / 1440;

  canvas.width = size.value === 'mobile' ? window.innerWidth : 1440;
  canvas.height = size.value === 'mobile' ? window.innerHeight : 1181;

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(frames[currentFrame], 0, 0, canvas.width, canvas.width * ratio);
}

onMounted(() => {
  targetElement = canvas.value;
  sequencePath = size.value === 'mobile' ? '/img-sequence/mobile/' : '/img-sequence/desktop/';
  fileName = size.value === 'mobile' ? 'mobile_' : 'desktop_';
  loadFrames();
});

onUnmounted(() => {
  if (unwatchTrigger) unwatchTrigger();
});
</script>
